<script>
import PageHeader from "@/components/page-header.vue";
import CreateForm from "@/views/pages/orders/rental_wagons/create/CreateForm.vue";

export default {
  name: "Create",
  components: {
    PageHeader, CreateForm
  },
  data() {
    return {
      title: 'Create Rental Wagon Order',
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "Create Rental Wagon Order",
          active: true,
        },
      ],
    }
  }
}
</script>

<template>
  <PageHeader :title="title" :items="items"/>

  <CreateForm/>
</template>

<style scoped>

</style>